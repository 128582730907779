import React, { useContext } from 'react'
import { Box, Image } from '@theme-ui/components'
import TextContent from '../Text/TextContent'
import shortid from 'shortid'
import { store } from '../../context/store'
import ImagesRenderer from '../Images/ImagesRenderer'
import storySelector from '../../helpers/storySelector'

export default function ContentBlockMain({ storyId, video, ...rest }) {
  const { globalState, dispatch } = useContext(store)
  const { stories } = globalState

  const { title, subtitle, body, images, buttons } = storySelector(
    storyId,
    stories
  )

  return (
    <Box {...rest} sx={{ ...styles.container, ...rest.sx }}>
      <TextContent
        sx={styles.textContent}
        className='content'
        title={title}
        subtitle={subtitle}
        text={body}
        buttons={buttons}
      />
    </Box>
  )
}

const styles = {
  container: {
    '.content': {
      '> :last-child': {
        marginBottom: '0rem',
      },
    },
  },
  textContent: {},
}
